import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, BoxProps } from '@mui/material';

import colors from '../../../../common/colors';

const useStyles = makeStyles(() => ({
  soldOut: {
    width: 'fit-content',
    height: '18px',
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.gray400,
    padding: '1px 3px',
    color: colors.gray601
  }
}));

const SoldOutBadge = (props: BoxProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box aria-hidden={false} aria-label="Sold out" className={`${classes.soldOut} badgeText`} {...props}>
      SOLD OUT
    </Box>
  );
};

export default SoldOutBadge;
