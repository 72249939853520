import { useSelector } from 'react-redux';
import { localizationSelectors } from '@/localization/localizationSelectors';

const useSodiumAlertForStore = () => {
  const localizationInfo = useSelector(localizationSelectors.storeDetails);

  return {
    isShowSodiumAlertForStore: !!localizationInfo?.showSodiumAlerts
  };
};

export default useSodiumAlertForStore;
