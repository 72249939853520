import {
  Button, Drawer, Grid, Typography
} from '@mui/material';
import React from 'react';
import CloseIcon from '../../../localization/icons/CloseIcon';
import styles from './InfoRail.styles';
import { railStyles } from '@/rail/Rail.styles';

export interface InfoRailProps {
  visible: boolean;
  onClose: () => void;
  children: JSX.Element;
  title?: string;
  id?: string;
}

export const railHeaderHeight = '60px';
export const railHeaderHeightMobileView = '48px';
export const zIndex = {
  LOWER_THAN_GOOGLE_AUTO_COMPLETE: 1149,
  HIGHEST: 9999
};

const InfoRail = ({
  visible,
  onClose,
  children,
  id,
  title = 'Product info'
}: InfoRailProps): JSX.Element => {
  const classes = styles();
  const railClasses = railStyles();
  return (
    <Drawer
      classes={{ paper: railClasses.paper }}
      style={{ zIndex: zIndex.LOWER_THAN_GOOGLE_AUTO_COMPLETE }}
      anchor="right"
      onClose={onClose}
      open={visible}
      role="dialog"
      aria-labelledby="info-rail-title"
      aria-modal="true"
      tabIndex={-1}
      id={id}
      data-testid={id}
    >
      <div>
        <div color="inherit" className={classes.root}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item classes={{ root: classes.titleItem }}>
              <Typography
                variant="h1"
                id="info-rail-title"
                className={classes.title}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Grid>{children}</Grid>
        <div className={classes.closeIconItem}>
          <Button
            data-testid="rail-close"
            aria-label="Close"
            onClick={onClose}
            classes={{ root: classes.closeIcon }}
          >
            <CloseIcon />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default InfoRail;
